<template>
  <div class="contatain">
    <div class="tab">
      <!-- tab栏 -->
      <div
        class="tab-item"
        v-for="(item, index) in tabList"
        :key="`a${index}`"
        :class="curTab === item.id ? 'tab-item-active' : ''"
        @click="curTab = item.id"
      >
        {{ item.name }}
      </div>
    </div>
    <filter-item v-show="curTab === 1" @dealFilter="dealFilter" />
    <filter-item v-show="curTab === 2" @dealFilter="dealFilter" />
    <div class="content-wrap">
      <el-card class="row2-card" v-loading="loading">
        <div class="flex-row flex-ac">
          <div class="title">数据表</div>
          <div class="table-remark">
            注：因微信数据延迟，昨天的部分数据可能未更新。
          </div>
        </div>
        <div v-show="curTab === 1" class="flex-row flex-as">
          <div class="computed-title">支付趋势</div>
          <div class="flex-column">
            <el-table
              class="m-b-20"
              border
              :data="tableData1.list1"
              :header-cell-style="thStyle"
              :cell-style="cellStyle"
              style="width: auto; flex: 0 1 auto"
            >
              <el-table-column prop="title" label="" width="80">
              </el-table-column>
              <el-table-column label="付费人次" width="130">
                <template slot-scope="scope">
                  {{ scope.row.num1 + scope.row.unit1 }}
                </template>
              </el-table-column>
              <el-table-column label="付费用户数" width="130">
                <template slot-scope="scope">
                  {{ scope.row.num2 + scope.row.unit2 }}
                </template>
              </el-table-column>
              <el-table-column label="现金收入" width="130">
                <template slot-scope="scope">
                  {{ scope.row.num3 + scope.row.unit3 }}
                </template>
              </el-table-column>
              <el-table-column label="剧本销量" width="130">
                <template slot-scope="scope">
                  {{ scope.row.num4 + scope.row.unit4 }}
                </template>
              </el-table-column>
            </el-table>
            <el-table
              border
              :data="tableData1.list2"
              :cell-style="cellStyle"
              :header-cell-style="thStyle"
              style="width: auto; flex: 0 1 auto"
            >
              <el-table-column prop="title" label="" width="80">
              </el-table-column>
              <el-table-column label="用户购买点券量">
                <template slot-scope="scope">
                  {{ scope.row.num1 }}点券
                </template>
              </el-table-column>
              <el-table-column label="用户花费点券量">
                <template slot-scope="scope">
                  {{ scope.row.num2 }}点券
                </template>
              </el-table-column>
              <el-table-column label="用户剩余点券量">
                <template slot-scope="scope">
                  {{ scope.row.num3 }}点券
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div v-show="curTab === 2" class="flex-row flex-as">
          <div class="computed-title">销售额排名</div>
          <div class="flex-column p-r">
            <el-table
              border
              :data="tableData2"
              :cell-style="cellStyle"
              :header-cell-style="thStyle"
              max-height="560"
              style="width: auto; flex: 0 1 auto"
              class="m-b-10"
            >
              <el-table-column width="80">
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column label="商品名称" width="130">
                <template slot-scope="scope">
                  {{ scope.row.productName }}
                </template>
              </el-table-column>
              <el-table-column label="现金销售额" width="130">
                <template slot-scope="scope">
                  {{ scope.row.cashSales }}元
                </template>
              </el-table-column>
              <el-table-column label="商品销量" width="130">
                <template slot-scope="scope">
                  {{ scope.row.productSales }}件
                </template>
              </el-table-column>
            </el-table>
            <div class="flex-row flex-ae justify-end">
              显示<input
                @input="changeCountListLimit"
                v-model="countListLimit"
                type="number"
                class="limit-input"
              />条
            </div>
          </div>
        </div>
        <div class="flex-row flex-as">
          <div class="title">数据图</div>
          <div class="flex-column">
            <el-checkbox-group
              v-if="curTab === 1"
              v-model="checkList1"
              style="width: 750px"
            >
              <el-checkbox
                v-for="(item, index) in nameList1.slice(0, 4)"
                :key="index"
                style="width: 100px"
                :label="index + 1 + ''"
                :disabled="isPieType1"
                >{{ item }}</el-checkbox
              ><br />
              <el-checkbox
                v-for="(item, index) in nameList1.slice(4, 7)"
                :key="index + 4"
                style="width: 100px"
                :label="index + 5 + ''"
                :disabled="isPieType1"
                >{{ item }}</el-checkbox
              >
            </el-checkbox-group>
            <!-- <el-checkbox-group
              v-if="curTab === 2"
              v-model="checkList2"
              style="width: 750px"
            >
              <el-checkbox
                v-for="(item, index) in nameList2"
                :key="index"
                style="width: 100px"
                :label="index + 1 + ''"
                :disabled="isPieType2"
                >{{ item }}</el-checkbox
              >
            </el-checkbox-group> -->
            <el-button
              v-if="curTab === 1"
              class="m-t-10"
              type="primary"
              size="mini"
              @click="showChart"
              :disabled="isPieType1"
              >展示数据</el-button
            >
            <!-- <el-button
              v-if="curTab === 2"
              class="m-t-10 m-b-10"
              type="primary"
              size="mini"
              @click="showChart"
              :disabled="isPieType2"
              >展示数据</el-button
            > -->
            <echart-item
              v-loading="loading"
              v-show="curTab === 1"
              :data="chartData1"
              :lineList="lineList1"
              :nameList="nameList1"
              :pieData1="wholePieData1"
              pieDataName1="消费（单位：点券）"
              @pieType="pieType"
              dataType="businessWhole"
            />
            <echart-item
              v-loading="loading"
              v-show="curTab === 2"
              :data="chartData2"
              :nameList="nameList2"
              :lineList="lineList2"
              dataType="businessCount"
              :noPie="true"
            />
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import FilterItem from "./common/component/FilterItem.vue";
import EchartItem from "./common/component/EchartItem.vue";
import { timestampToDate, debounce } from "@tools";
export default {
  components: {
    FilterItem,
    EchartItem,
  },
  data() {
    return {
      tabList: [
        { name: "整体总览", id: 1 },
        { name: "点券排名", id: 2 },
      ],
      checkList1: ["1"], // 左tab的选项数组
      checkList2: ["1"], // 右tab的选项数组
      curTab: 1,
      tableData1: {}, // 左tab的所有表格数据
      tableData2: [], // 右tab的所有表格数据
      loading: false,
      tableFilter1: {
        day: 30,
        ignoreFlag: true,
        platform: "",
        endTime: timestampToDate(new Date().getTime(), false),
        startTime: timestampToDate(
          new Date().getTime() - 3600 * 1000 * 24 * 30,
          false
        ),
      }, // 左tab的顶部筛选条件
      tableFilter2: {
        ignoreFlag: true,
        platform: "",
        endTime: timestampToDate(new Date().getTime(), false),
        startTime: timestampToDate(
          new Date().getTime() - 3600 * 1000 * 24 * 30,
          false
        ),
        limit: 5,
      }, // 右tab的顶部筛选条件
      nameList1: [
        "付费人次",
        "付费用户数",
        "现金收入",
        "剧本销量",
        "用户购买点券量",
        "用户花费点券量",
        "用户点券余额",
      ], // 左tab的选项名称数组，以下同理
      nameList2: ["现金销售额", "商品销量"],
      chartData1: [], // 左tab的图表数据
      chartData2: [],
      wholePieData1: [], // 左tab饼状数据1
      lineList1: ["付费人次", "付费用户数"], // 需要折线的选项
      lineList2: ["现金销售额", "商品销量"], // 需要折线的选项
      isPieType1: false,
      isPieType2: false,
      pieCheckList1: ["5", "6", "7"], // 饼状图需要勾选死的选项
      countListLimit: 5, // 点券排名表格的limit
    };
  },
  async created() {
    this.getBusinessDataList();
  },
  methods: {
    getBusinessDataList: debounce(async function (needChart = true) {
      this.loading = true;
      const params = this[`tableFilter${this.curTab}`],
        type = this.curTab === 1 ? "Whole" : "Count";
      params.ignoreMinute = localStorage.ignoreMinute;
      const data = await this.$api.chiguaData[`getBusiness${type}DataList`](
        params
      ).finally(() => {
        this.loading = false;
      });
      // 处理数据
      if (this.curTab === 1) {
        let list1 = [],
          list2 = [],
          unitArr = ["次", "人", "元", "本"],
          obj1 = { title: "总数" },
          obj2 = { title: "日平均值" };
        data.paymentTrends.forEach((item, index) => {
          let key = item.key;
          obj1[`num${key}`] = item.sum;
          obj1[`unit${key}`] = unitArr[index];
          obj2[`num${key}`] = item.dayAvg;
          obj2[`unit${key}`] = unitArr[index];
        });
        list1.push(obj1, obj2);
        obj1 = { title: "总数" };
        obj2 = { title: "日平均值" };
        data.couponTrends.forEach((item) => {
          let key = item.key;
          obj1[`num${key}`] = item.sum;
          obj2[`num${key}`] = item.dayAvg;
        });
        list2.push(obj1, obj2);
        this.tableData1 = { list1, list2 };
      } else {
        this.tableData2 = data.data;
      }
      this.curTab === 1 && this.getPieData(this.tableData1.list2[0]); // 本页面只有左tab有饼状图
      needChart && this.getBusinessDataChart();
    }, 300),
    async getBusinessDataChart() {
      this.loading = true;
      const params = this[`tableFilter${this.curTab}`],
        type = this.curTab === 1 ? "Whole" : "Count",
        checkList = this[`isPieType${this.curTab}`] // 防止饼图状态时，筛选，然后影响到后面切其他图
          ? "pieCheckList"
          : "checkList";

      Object.assign(params, {
        dataFlagList: this[`${checkList}${this.curTab}`].sort().toString(),
        ignoreMinute: localStorage.ignoreMinute,
      });
      // params.dataFlagList = this[`${checkList}${this.curTab}`]
      //   .sort()
      //   .toString();
      const data = await this.$api.chiguaData[`getBusiness${type}DataChart`](
        params
      ).finally(() => {
        this.loading = false;
      });
      if (this.curTab === 1) {
        this.chartData1 = data;
      } else {
        const list = data.couponTopDataTableVO;
        list.reverse();

        this.chartData2 = {
          dateArr: list.map((item) => item.productName),
          dataArr1: [
            {
              name: "现金销售额",
              type: "bar",
              data: list.map((item) => item.cashSales),
            },
            {
              name: "商品销量",
              type: "bar",
              data: list.map((item) => item.productSales),
            },
          ],
          dataArr2: [],
        };
      }
    },
    dealFilter(params, day) {
      this.curTab === 1 && (params.day = day);
      this.curTab === 2 && (params.limit = this.countListLimit);
      this[`tableFilter${this.curTab}`] = params;
      this.getBusinessDataList();
    },
    showChart() {
      const tab = this.curTab,
        params = this[`tableFilter${tab}`];
      params.dataFlagList = this[`checkList${tab}`].sort().toString();
      this.getBusinessDataChart(params);
    },
    getPieData(data) {
      // 处理饼图的数据
      this.wholePieData1.splice(
        0,
        3,
        { name: "用户购买点券量", value: data.num1 },
        { name: "用户花费点券量", value: data.num2 },
        { name: "用户点券余额", value: data.num3 }
      );
    },
    pieType(isPie) {
      // 切换到饼图时的回调，变灰
      const cur = this.curTab === 1 ? 1 : 2,
        isPieType = this[`isPieType${cur}`];
      if ((!isPieType && isPie) || (isPieType && !isPie)) {
        [this[`checkList${cur}`], this[`pieCheckList${cur}`]] = [
          this[`pieCheckList${cur}`],
          this[`checkList${cur}`],
        ];
      }
      this[`isPieType${cur}`] = isPie;
    },
    changeCountListLimit() {
      if (this.countListLimit === "") return;
      const count = Number(this.countListLimit);
      if (count < 0) {
        this.tableFilter2.limit = 0;
        this.countListLimit = 0;
        return;
      }
      this.tableFilter2.limit = count;
      this.countListLimit = count;
      this.getBusinessDataList(false);
    },
    openDialog(ref) {
      this.$refs[ref].showDialog = true;
    },
    thStyle() {
      return {
        background: "rgba(197, 192, 255, 0.1)",
        color: "#000",
        textAlign: "center",
      };
    },
    cellStyle() {
      return {
        fontWeight: "500",
        color: "#000",
        textAlign: "center",
      };
    },
  },
  watch: {
    curTab() {
      this.getBusinessDataList();
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
@import "./common/style/dataForm.scss";
@import "./common/style/dataTable.scss";
.item-date {
  text-align: left;
}
.limit-input {
  padding-left: 15px;
  width: 30px;
  border: none;
  outline: none;
  border-bottom: 1px solid black;
}
</style>